var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "appeal-edit" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            { staticClass: "main-header" },
            [_c("portal-page-header")],
            1
          ),
          _c("el-main", [
            _c(
              "div",
              { staticClass: "main-content flex-column" },
              [
                _c("div", { staticClass: "appeal-text" }, [_vm._v("账号申诉")]),
                _c(
                  "el-form",
                  {
                    key: "mainForm",
                    ref: "mainForm",
                    staticClass: "zwx-form edit-form",
                    staticStyle: { "margin-top": "48px" },
                    attrs: { model: _vm.mainForm, rules: _vm.rules },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {},
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "138px",
                              label: "统一社会信用代码：",
                              prop: "creditCode"
                            }
                          },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "416px !important" },
                                attrs: {
                                  placeholder: "请输入统一社会信用代码"
                                },
                                model: {
                                  value: _vm.mainForm.creditCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.mainForm, "creditCode", $$v)
                                  },
                                  expression: "mainForm.creditCode"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-input__icon el-icon-search",
                                  attrs: { slot: "suffix" },
                                  on: {
                                    click: function($event) {
                                      return _vm.antiShake(_vm.search)
                                    }
                                  },
                                  slot: "suffix"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "138px",
                              label: "单位名称：",
                              prop: "unitName"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "416px !important" },
                              attrs: {
                                placeholder: "请输入",
                                maxlength: "100",
                                clearable: ""
                              },
                              model: {
                                value: _vm.mainForm.unitName,
                                callback: function($$v) {
                                  _vm.$set(_vm.mainForm, "unitName", $$v)
                                },
                                expression: "mainForm.unitName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "138px",
                              label: "所属地区：",
                              prop: "bizZone"
                            }
                          },
                          [
                            _c("zwx-select-area", {
                              ref: "bizZoneArea",
                              staticClass:
                                "cascader-area-biz-zone select-component",
                              attrs: {
                                placeholder: "请选择",
                                zoneCode12From: _vm.zoneCode12From,
                                zoneTypeMax: "0",
                                zoneTypeMin: "4",
                                showFullName: true
                              },
                              on: { change: _vm.nativePlaceChange }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "138px",
                              label: "申请人姓名：",
                              prop: "appealMan"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "416px !important" },
                              attrs: {
                                placeholder: "请输入",
                                maxlength: "100",
                                clearable: ""
                              },
                              model: {
                                value: _vm.mainForm.appealMan,
                                callback: function($$v) {
                                  _vm.$set(_vm.mainForm, "appealMan", $$v)
                                },
                                expression: "mainForm.appealMan"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "138px",
                              label: "身份证号：",
                              prop: "appealIdc"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "416px !important" },
                              attrs: {
                                placeholder: "请输入证件号码",
                                maxlength: "18",
                                clearable: ""
                              },
                              model: {
                                value: _vm.mainForm.appealIdc,
                                callback: function($$v) {
                                  _vm.$set(_vm.mainForm, "appealIdc", $$v)
                                },
                                expression: "mainForm.appealIdc"
                              }
                            }),
                            _c("div", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.idCodeSuccessShow,
                                  expression: "idCodeSuccessShow"
                                }
                              ],
                              staticClass: "idType-pass",
                              class: _vm.idCodeSuccessShow
                                ? "filled chis-icon-filled-completed"
                                : "",
                              staticStyle: { color: "#10C77B" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-row" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "138px",
                              label: "营业执照复印件：",
                              prop: "bizLicense"
                            }
                          },
                          [
                            _c(
                              "file-upload",
                              {
                                ref: "coverPictureRef",
                                attrs: {
                                  accept: ".png,.jpeg,.jpg,.JPG,.JPEG,.PNG,",
                                  size: 5 * 1024 * 1024,
                                  sizeInfo: "5M"
                                },
                                on: {
                                  showFileList: _vm.picFileShowFileList,
                                  success: _vm.picFileUploadSuccess
                                }
                              },
                              [
                                _vm.$zwxBase.verifyIsBlank(
                                  _vm.mainForm.annexList
                                )
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass:
                                          "zwx-button zwx-button-icontext-26",
                                        attrs: {
                                          type: "primary",
                                          icon: "el-icon-upload2"
                                        }
                                      },
                                      [_vm._v("上传")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.$zwxBase.verifyIsNotBlank(
                              _vm.mainForm.annexList
                            )
                              ? _c(
                                  "div",
                                  { staticClass: "show-file" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass:
                                          "zwx-button zwx-button-text-26",
                                        on: {
                                          click: function($event) {
                                            return _vm.openFilePreview1(
                                              _vm.mainForm.annexList,
                                              0
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("查看")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass:
                                          "zwx-button zwx-button-text-26",
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deletionPicFile(0)
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("div", { staticClass: "limit-css" }, [
                          _vm._v("注意：支持5M以内的PNG、JPG图片，每张最大5M。")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "138px",
                              label: "手机号码：",
                              prop: "appealPhone"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "416px !important" },
                              attrs: {
                                placeholder: "请输入",
                                maxlength: "11",
                                clearable: ""
                              },
                              model: {
                                value: _vm.mainForm.appealPhone,
                                callback: function($$v) {
                                  _vm.$set(_vm.mainForm, "appealPhone", $$v)
                                },
                                expression: "mainForm.appealPhone"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "code",
                              label: "验证码：",
                              "label-width": "138px"
                            }
                          },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "code-input",
                                staticStyle: { width: "416px" },
                                attrs: { placeholder: "请输入验证码" },
                                on: {
                                  change: function($event) {
                                    _vm.double = "error"
                                      ? (_vm.double = true)
                                      : ""
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.handler("msForm")
                                  }
                                },
                                model: {
                                  value: _vm.mainForm.code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.mainForm, "code", $$v)
                                  },
                                  expression: "mainForm.code"
                                }
                              },
                              [
                                _c("zwx-verify-code", {
                                  ref: "verify",
                                  attrs: { slot: "append" },
                                  slot: "append"
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "138px",
                              label: "短信验证码：",
                              prop: "smsVerifyCode"
                            }
                          },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "416px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入短信验证码"
                                },
                                model: {
                                  value: _vm.mainForm.smsVerifyCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.mainForm, "smsVerifyCode", $$v)
                                  },
                                  expression: "mainForm.smsVerifyCode"
                                }
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "msg-button",
                                      style:
                                        _vm.showtime !== null
                                          ? "pointer-events: none"
                                          : "",
                                      on: {
                                        click: function($event) {
                                          return _vm.antiShake(
                                            _vm.validMobileTel
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm.showtime === null
                                        ? _c("span", [_vm._v(" 发送 ")])
                                        : _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "#0a4dea!important"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.showtime) + " "
                                              )
                                            ]
                                          )
                                    ]
                                  )
                                ])
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "appeal-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { background: "#5574DF" },
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.antiShake(_vm.appealSubmit)
                          }
                        }
                      },
                      [_vm._v("申诉")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "bottom" }, [
        _c("div", { staticClass: "bottom-text" }, [
          _vm._v(
            "Copyright © " +
              _vm._s(_vm.domainNumber) +
              " | " +
              _vm._s(_vm.technicalSupport)
          )
        ])
      ]),
      _c("file-preview", { ref: "filePreview" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }