<template>
  <div class="appeal-edit">
    <el-container>
      <el-header class="main-header">
        <portal-page-header />
      </el-header>
      <el-main>
        <div class="main-content flex-column">
          <div class="appeal-text">账号申诉</div>
          <el-form class="zwx-form edit-form" style="margin-top:48px" key="mainForm" ref="mainForm" :model="mainForm" :rules="rules" @submit.native.prevent>
            <div class="">
              <el-form-item label-width="138px" label="统一社会信用代码：" prop="creditCode">
                <el-input style="width:416px !important" v-model="mainForm.creditCode" placeholder="请输入统一社会信用代码">
                  <i slot="suffix" class="el-input__icon el-icon-search" @click="antiShake(search)"></i>
                </el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label-width="138px" label="单位名称：" prop="unitName">
                <el-input style="width:416px !important" v-model="mainForm.unitName" placeholder="请输入" maxlength="100" clearable />
              </el-form-item>
            </div>
            <div>
              <el-form-item label-width="138px" label="所属地区：" prop="bizZone">
                <zwx-select-area class="cascader-area-biz-zone select-component" ref="bizZoneArea" placeholder="请选择" @change="nativePlaceChange" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="true" />
              </el-form-item>
            </div>
            <div>
              <el-form-item label-width="138px" label="申请人姓名：" prop="appealMan">
                <el-input style="width:416px !important" v-model="mainForm.appealMan" placeholder="请输入" maxlength="100" clearable />
              </el-form-item>
            </div>
            <div>
              <el-form-item label-width="138px" label="身份证号：" prop="appealIdc">
                <el-input style="width:416px !important" v-model="mainForm.appealIdc" placeholder="请输入证件号码" maxlength="18" clearable />
                <div class="idType-pass" v-show="idCodeSuccessShow" :class="idCodeSuccessShow ? 'filled chis-icon-filled-completed' : ''" style="color:#10C77B"></div>
              </el-form-item>
            </div>
            <div class="flex-row">
              <el-form-item label-width="138px" label="营业执照复印件：" prop="bizLicense">
                <file-upload ref="coverPictureRef" accept=".png,.jpeg,.jpg,.JPG,.JPEG,.PNG," @showFileList="picFileShowFileList" @success="picFileUploadSuccess" :size="5 * 1024 * 1024" sizeInfo="5M">
                  <el-button class="zwx-button zwx-button-icontext-26" type="primary" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(mainForm.annexList)">上传</el-button>
                </file-upload>
                <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(mainForm.annexList)">
                  <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview1(mainForm.annexList, 0)">查看</el-button>
                  <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletionPicFile(0)">删除</el-button>
                </div>
              </el-form-item>
              <div class="limit-css">注意：支持5M以内的PNG、JPG图片，每张最大5M。</div>
            </div>
            <div>
              <el-form-item label-width="138px" label="手机号码：" prop="appealPhone">
                <el-input style="width:416px !important" v-model="mainForm.appealPhone" placeholder="请输入" maxlength="11" clearable />
              </el-form-item>
            </div>
            <div>
              <el-form-item prop="code" label="验证码：" label-width="138px">
                <el-input placeholder="请输入验证码" class="code-input" v-model="mainForm.code" @keyup.enter.native="handler('msForm')" @change="double = 'error' ? (double = true) : ''" style="width:416px">
                  <zwx-verify-code slot="append" ref="verify" />
                </el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label-width="138px" label="短信验证码：" prop="smsVerifyCode">
                <el-input style="width:416px " v-model="mainForm.smsVerifyCode" clearable placeholder="请输入短信验证码">
                  <template slot="append">
                    <div class="msg-button" @click="antiShake(validMobileTel)" :style="showtime !== null ? 'pointer-events: none' : ''">
                      <span v-if="showtime === null">
                        发送
                      </span>
                      <span v-else style="color: #0a4dea!important">
                        {{ showtime }}
                      </span>
                    </div>
                  </template>
                </el-input>
              </el-form-item>
            </div>
          </el-form>
          <div class="appeal-btn">
            <el-button style="background: #5574DF;" type="primary" @click="antiShake(appealSubmit)">申诉</el-button>
          </div>
        </div>
      </el-main>
    </el-container>
    <div class="bottom">
      <div class="bottom-text">Copyright © {{ domainNumber }} | {{ technicalSupport }}</div>
    </div>
    <!--文件预览弹框-->
    <file-preview ref="filePreview" />
  </div>
</template>

<script>
export default {
  name: 'AppealEdit',
  components: {},
  props: {},
  data() {
    return {
      api: this.$store.state.api,
      domainNumber: this.$store.state.domainNumber,
      technicalSupport: this.$store.state.technicalSupport,
      folder: '/supervision/account_appeal', //文件存储路径
      zoneCode12From: '000000000000',
      loading: null,
      // 计时器,注意需要进行销毁
      timeCounter: null,
      // null 则显示按钮 秒数则显示读秒
      showtime: null,
      // 短信验证码 替代用户uuid
      uuid: '',
      idCodeSuccessShow: false,
      tbEmployerExp: {},
      mainForm: {
        creditCode: '',
        unitName: '',
        bizZone: '',
        zoneType: '',
        appealMan: '',
        appealIdc: '',
        appealPhone: '',
        bizLicense: '',
        //验证码
        smsVerifyCode: '',
        //图片验证码
        code: '',
        annexList: [], //封面图片集合
      },
      rules: {
        creditCode: [
          { required: true, message: '请输入统一社会信用代码', trigger: ['change', 'blur'] },
          { validator: this.$validate.creditCode, trigger: ['blur'] },
        ],
        unitName: [{ required: true, message: '请输入单位名称', trigger: ['change', 'blur'] }],
        appealMan: [{ required: true, message: '请输入申诉人姓名', trigger: ['change', 'blur'] }],
        bizZone: [
          { required: true, message: '请选择作业场所地区', trigger: ['change'] },
          { validator: this.validateBizZone, trigger: ['blur', 'change'] },
        ],
        bizLicense: [{ required: true, message: '请上传营业执照复印件', trigger: ['change', 'blur'] }],
        appealIdc: [
          { required: true, message: '请输入身份证号', trigger: ['change', 'blur'] },
          { validator: this.validateIdCode, trigger: ['blur'] },
        ],
        appealPhone: [
          { required: true, message: '请输入手机号码', trigger: ['change', 'blur'] },
          { validator: this.validateMobile, trigger: ['blur'] },
        ],
        smsVerifyCode: [{ required: true, message: '请输入短信验证码', trigger: ['change', 'blur'] }],
        code: [{ required: true, message: '请输入验证码', trigger: ['change', 'blur'] }],
      },
      timer: null,
    }
  },
  computed: {},
  watch: {},
  created() {
    this.zoneCode12From = '000000000000'
  },
  mounted() {
    this.$refs.bizZoneArea.initSearch()
  },
  methods: {
    // 点击申诉
    appealSubmit() {
      this.$refs['mainForm'].validate(valid => {
        if (valid) {
          this.loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.3)',
          })
          let data = this.mainForm
          data.verifyUid = this.$refs.verify.uid
          this.$system.postJson(
            this.api + '/zky/employer/submitEmployerAccountAppealForGd-0',
            data,
            false,
            true,
            data => {
              if (data.type === '00') {
                this.loading.close()
                this.loading = null
                this.$router.push({ name: 'AppealProgress', params: {} })
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
                this.loading.close()
                this.loading = null
              } else {
                this.$notify.error({ title: '错误', message: data.mess })
                this.loading.close()
                this.loading = null
              }
            },
            this.error
          )
        }
      })
    },
    /**
     * 营业执照文件上传显示回调
     */
    picFileShowFileList(showList) {
      showList.forEach(item => {
        let flag = this.mainForm.annexList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.mainForm.annexList.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUid()
      }
    },
    // 获取营业执照文件的UID
    generateFilesUid() {
      this.$emit('loading', true)
      let data = {
        folder: this.folder,
      }
      this.$system.get(this.api + '/systematic/tools/getAnnexUploadUid-0', data, false, false, data => {
        if (data.type === '00') {
          this.$refs.coverPictureRef.submit(data.annexUploadUid)
          this.$emit('loading', false)
        } else {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },
    /**
     * 营业执照文件上传成功回调
     */
    picFileUploadSuccess(fileList) {
      // 文件集合
      if (this.$zwxBase.verifyIsNotBlank(this.mainForm.annexList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.mainForm.annexList) {
          for (let j in fileList) {
            if (this.mainForm.annexList[i].fileName === fileList[j].fileName) {
              this.mainForm.annexList[i].filePath = fileList[j].filePath
              this.mainForm.bizLicense = this.$zwxBase.verifyIsNotBlank(this.mainForm.annexList) ? this.mainForm.annexList[0].filePath : null
            }
          }
        }
      }
      this.$refs.mainForm.validateField('bizLicense')
    },
    /**
     * 删除营业执照文件
     */
    deletionPicFile(index) {
      let fileName = this.mainForm.annexList[index].fileName
      let filePath = this.mainForm.annexList[index].filePath
      this.mainForm.annexList.splice(index, 1)
      this.mainForm.bizLicense = ''
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.coverPictureRef.deleteIndex(index)
      }
      let fileList = this.$refs.coverPictureRef.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.coverPictureRef.deleteIndex(j)
            fileList = this.$refs.coverPictureRef.showFileList || []
            continue
          }
        }
      }
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      // 直接调用文件预览组件的方法
      this.$refs.filePreview.initPreviewFile(fileList, index)
    },
    antiShake(callBack) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
        this.timer = setTimeout(() => {
          clearTimeout(this.timer)
          this.timer = null
          callBack()
        }, 500)
      } else {
        this.timer = setTimeout(() => {
          clearTimeout(this.timer)
          this.timer = null
          callBack()
        }, 200)
      }
    },
    // 点击社会信用代码查询单位信息
    search() {
      this.$refs['mainForm'].validateField('creditCode', err => {
        if (!err) {
          this.loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.3)',
          })
          let data = {
            socialCode: this.$zwxSm.sm4JsEncrypt(this.mainForm.creditCode),
          }
          this.$system.post(
            this.api + '/systematic/getUnitBySocialCode-0',
            data,
            false,
            true,
            data => {
              if (data.type === '00') {
                if (this.$zwxBase.verifyIsBlank(data.unit)) {
                  this.$system.notify('错误', '该企业尚未注册', 'error')
                } else {
                  this.tbEmployerExp = data.unit
                  this.mainForm.unitName = data.unit.unitName
                }
                this.loading.close()
                this.loading = null
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
                this.loading.close()
                this.loading = null
              } else {
                this.$notify.error({ title: '错误', message: data.mess })
                this.loading.close()
                this.loading = null
              }
            },
            this.error
          )
        }
      })
    },
    //所属地区
    nativePlaceChange() {
      this.mainForm.zoneType = this.$refs.bizZoneArea.getChooseArea().zoneType
      this.mainForm.bizZone = this.$refs.bizZoneArea.getChooseArea() == null ? null : this.$refs.bizZoneArea.getChooseArea().zoneCode12
    },
    /**
     * 地区校验
     */
    validateBizZone(rule, value, callback) {
      if (this.mainForm.zoneType < 3) {
        callback(new Error('请精确到区县级或街镇级'))
      }
      callback()
    },
    //发送验证码
    // 倒计时显示处理
    countDownText(s) {
      this.showtime = `${s}s后重新获取`
    },
    countDown(times) {
      const self = this
      // 时间间隔 1秒
      const interval = 1000
      let count = 0
      self.timeCounter = setTimeout(countDownStart, interval)

      function countDownStart() {
        if (self.timeCounter == null) {
          return false
        }
        count++
        self.countDownText(times - count + 1)
        if (count > times) {
          clearTimeout(self.timeCounter)
          self.showtime = null
        } else {
          self.timeCounter = setTimeout(countDownStart, interval)
        }
      }
    },
    // 校验手机号
    validMobileTel() {
      const validList = ['creditCode', 'unitName', 'appealMan', 'appealIdc', 'bizZone', 'bizLicense', 'appealPhone', 'code']
      let isSuccessList = []
      this.$refs.mainForm.validateField(validList, valid => {
        isSuccessList.push(!!valid)
      })
      if (isSuccessList.includes(true)) return
      let data = {
        uid: this.tbEmployerExp.uid,
        unitName: this.mainForm.unitName,
        creditCode: this.$zwxSm.sm4JsEncrypt(this.mainForm.creditCode),
        mobileTel: this.$zwxSm.sm4JsEncrypt(this.mainForm.appealPhone),
      }
      this.$emit('loading', true)
      this.$system.post(
        this.api + '/systematic/getUserListByNameCreditCode-0',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            if (data.bool) {
              this.send()
              return
            }
            this.$system.msgbox('', '提示', '该手机号号码与系统中的账号不一致，是否确认申诉?', '确定', '取消', () => {
              this.send()
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    //点击发送
    send() {
      // this.validMobileTel()
      if (!this.mainForm.appealPhone) {
        this.$refs['mainForm'].validateField('appealPhone')
        return
      }
      if (!this.mainForm.code) {
        this.$refs['mainForm'].validateField('code')
        return
      }
      if (!this.mainForm.appealMan) {
        this.$refs['mainForm'].validateField('appealMan')
        return
      }
      // this.$refs['mainForm'].validateField('appealPhone', (err) => {
      //   if (!err) {
      this.loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.3)',
      })
      let data = {
        unitName: this.mainForm.unitName,
        creditCode: this.mainForm.creditCode,
        verifyUid: this.$refs.verify.uid,
        verifyCode: this.mainForm.code,
        mobileTel: this.mainForm.appealPhone,
        appealMan: this.mainForm.appealMan,
        messageType: 1,
      }

      // 发送短信验证码
      this.$system.get(
        this.api + '/zky/employer/getAppealMobileCountAndSendSms-0',
        data,
        false,
        true,
        data => {
          if (data.type === '00') {
            this.countDown(60)
            this.$system.notify('成功', data.mess, 'success')
          } else if (data.type === '99') {
            this.$refs.verify.imgCodeReload()
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$refs.verify.imgCodeReload()
            this.$system.notify('错误', data.mess, 'error')
          }
          this.loading.close()
          this.loading = null
        },
        this.error
      )
    },
    /**
     * 身份证号校验
     */
    validateIdCode(rule, value, callback) {
      this.idCodeSuccessShow = false
      return this.$validate.idcCode(rule, value, val => {
        if (this.$zwxBase.verifyIsBlank(val)) {
          this.idCodeSuccessShow = true
          callback()
        } else {
          callback(new Error('请输入正确的身份证号码'))
        }
      })
    },
    /**
     * 手机号校验
     */
    validateMobile(rule, value, callback) {
      var patt = /^[1][3-9][\d]{9}/
      if (!patt.test(value)) {
        callback(new Error('请输入正确手机号'))
      }
      callback()
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.loading.close()
      this.loading = null
    },
  },
}
</script>

<style scoped lang="less">
.flex-row {
  display: flex;
  align-items: center;
}

.flex-row-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row-align-end {
  display: flex;
  align-items: flex-end;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.appeal-edit {
  height: 100%;
  .main-header {
    height: 85px !important;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 4px rgba(222, 227, 236, 0.8);
    z-index: 2;
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 16px;
    color: #999999;
    .bottom-text {
      position: fixed;
      bottom: 10px;
    }
  }
  .main-content {
    margin-top: 48px;
    .limit-css {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 22px;
      margin-bottom: 16px;
    }
    .idType-pass {
      position: absolute;
      top: 0px;
      right: -30px;
      z-index: 2;
      height: 24px;
      display: flex;
      justify-content: center;
    }
    /deep/.el-icon-circle-close {
      line-height: 46px !important;
    }
    /deep/.zwx-form .el-form-item__content {
      line-height: 46px !important;
    }
    .select-component {
      /deep/ .zwx-input {
        width: 416px !important;
        height: 46px !important;
        line-height: 46px !important;
        cursor: pointer;
      }

      /deep/ .el-input__suffix {
        right: 6px !important;
      }

      /deep/ .el-input__inner {
        border: 1px solid rgba(215, 218, 224, 1) !important;
        border-radius: 4px !important;
        width: 416px;
        padding-left: 15px !important;
      }

      /deep/ .zwx-input .el-input__suffix .el-input__suffix-inner .el-input__icon {
        margin-top: 0;
        line-height: 46px !important;
      }

      /deep/ .zwx-input-up {
        margin-left: -32px !important;
      }
    }
    .msg-button {
      cursor: pointer;
      width: 100px;
      height: 100%;
      text-align: center;
    }
    .code-input {
      /deep/ .el-input__inner {
        height: 52px !important;
      }
      /deep/ .el-input-group__append {
        padding: 0 !important;
      }
    }
    /deep/ .el-form-item__label {
      line-height: 46px !important;
    }
    /deep/.el-input__inner {
      height: 46px !important;
      line-height: 46px !important;
    }
    .appeal-text {
      font-size: 24px;
      font-family: 'Harmony Medium';
      font-weight: 500;
      color: #3d3f44;
      line-height: 24px;
    }
    .appeal-btn {
      margin-top: 32px;
      /deep/.el-button {
        width: 264px !important;
      }
    }
  }
}
</style>
